<template>
  <b-card no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col md="12" class="revenue-report-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">Dépenses salariales</h4>
          <div v-if="false" class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span
                class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Bénéfices</span>
            </div>
            <div class="d-flex align-items-center">
              <span
                class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Dépenses</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          ref="testref"
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="depensesSalarialesChartData.chartOptions"
          :series="depensesSalarialesChartData.series"
        />
      </b-col>

      <!-- <b-col
        md="4"
        class="budget-wrapper"
      >
        <b-dropdown
          text="2020"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="year in depenses_salariales.years"
            :key="year"
          >
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>

        <h2 class="mb-25">
          ${{ depenses_salariales.price }}
        </h2>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">Budget:</span>
          <span>56,800</span>
        </div>
        <vue-apex-charts
          id="budget-chart"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="budgetChart.series"
        />

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          Increase Budget
        </b-button>
      </b-col> -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  //   BDropdown,
  //   BDropdownItem,
  //   BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import utilsService from "@/services/utils/utils.service";
import { mapActions, mapState } from "vuex";

// store modules and vuex utilities
import statisticsStoreModule from "@/store/statistics";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

const monthsList = utilsService.getListMonths();
const defaultSerieData = [{ data: monthsList.map(e=> 0)}];

const depensesSalarialesChartData = {
  series: [...defaultSerieData],
  chartOptions: {
    chart: {
      stacked: true,
      type: "bar",
      toolbar: { show: false },
    },
    grid: {
      padding: {
        top: -20,
        bottom: -10,
      },
      yaxis: {
        lines: { show: false },
      },
    },
    xaxis: {
      categories: monthsList,
      labels: {
        style: {
          colors: "#b9b9c3",
          fontSize: "0.86rem",
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [$themeColors.warning],
    plotOptions: {
      bar: {
        columnWidth: "17%",
        endingShape: "rounded",
      },
      distributed: true,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#b9b9c3",
          fontSize: "0.86rem",
        },
      },
    },
  },
};
export default {
    setup() {
    const requiredStoreModules = [
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  }, 
  components: {
    VueApexCharts,
    // BDropdown,
    // BDropdownItem,
    BCard,
    // BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    series: {
      type: Array,
      default: () => {
        return [...defaultSerieData];
      },
    },
  },
  data() {
    return {
      monthsList,
      depenses_salariales: {},
      depensesSalarialesChartData,
      depensesSalarialeChartRef:'depensesSalarialeChartRef'
    };
  },
  async mounted() {},
  watch: {
    series: {
      deep: true, 
      handler(val, old) {
        this.depensesSalarialesChartData = {
          ...depensesSalarialesChartData,
          series: val,
        };
        // this.$refs[this.depensesSalarialeChartRef].setOption(option, {});
      },
    },
  },
  methods: {
    ...mapActions("statistics", {
      action_getStatisticsExploitationEtablissements:
        "getStatisticsExploitationEtablissements",
    }),
  },
};
</script>
